import React from "react";

import "./jumbotron.scss";

const Jumbotron = ({ splash, banner }) => {
  return (
    <div className="jumbotron-container">
      {banner && <div className="jumbotron-title-background">{banner}</div>}
      <img src={splash} alt="Logo" width="100%" />
    </div>
  );
};

export default Jumbotron;
