import React from "react";

import Jumbotron from "../misc/jumbotron/jumbotron";
import WhatsappSVG from "../misc/icons/whatsapp-svg/whatsapp-svg";
import EmailSVG from "../misc/icons/email-svg/email-svg";

import "./event_details.scss";

import { assetsURL } from "../../constants.js";

const SportivaLogo = `${assetsURL}/svg/logo-letras-negro.svg`;
const ChevronDown = `${assetsURL}/svg/chevron-down.svg`;

const EventDetails = ({ event_details, modal_trigger }) => {
  const {
    title,
    event,
    description,
    event_date,
    location,
    taking_reservations,
    event_open,
    official_partner,
    packages,
    reservation_fee_caveat
  } = event_details;

  return (
    <div className="event-details-container">
      <div className="event-details-jumbotron">
        <Jumbotron
          splash={
            assetsURL + "/img/events/" + event + "/" + event + "-400x200.jpg"
          }
        />
      </div>
      <div className="event-details-header">
        <h1 className="event-title">{title}</h1>
        {official_partner && (
          <div className="event-partner-badge">Tour Operador Oficial</div>
        )}
      </div>


      <div className="event-description">{description}.</div>
      <div className="event-misc-group">
        <div className="event-info">
          <h4 className="misc-group-header">Información</h4>
          <div className="event-info-header">
            <span>Fecha: </span>
            {Array.isArray(event_date) ? (
              <ul>
                {event_date.map((date) => (
                  <li key={`${date}-key`}>{date}</li>
                ))}
              </ul>
            ) : (
              event_date
            )}
          </div>
          <div className="event-info-header">
            <span>Lugar: </span>
            {location}.
          </div>
          <div className="event-info-header">
            <span>Paquetes de Viaje:</span> Puede reservar con Sportiva.
          </div>
          <div className="event-info-header">
            <span>Inscripción al evento:</span> Puede inscribirse a través de
            Sportiva.
          </div>
        </div>
        <div className="contact-info">
          <h4 className="misc-group-header">Contacto y Reservación</h4>
          <div className="btn-group">
            <div className="btn-element">
              <WhatsappSVG event_title={title} />
              <div className="btn-label">Whatsapp</div>
            </div>
            <div className="btn-element">
              <EmailSVG modal_trigger={modal_trigger} />
              <div className="btn-label">Correo</div>
            </div>
          </div>
        </div>
      </div>
      {packages.length > 0 && <div className="event-misc-group">
        <img className="event-chevron-down" src={ChevronDown} alt="Mas abajo" />
      </div>}
      <div className="event-misc-group">
        {packages.map((aPackage) => {
          const {
            reservation_fee,
            reservation_fee_currency,
            reservation_breakdown,
            refundable,
            reservation_extra_caveat,
            reservation_fee_caveat
          } = aPackage.reservation;

          console.log("--->", reservation_fee_caveat);
          return (
            <div key={`event-${aPackage.name}`} className="event-package">
              <div className="event-package-main">
                <h1 className="event-package-header">{aPackage.name}</h1>
                <div className="event-package-main-misc">
                  <div className="event-package-dates-fromto">
                    <span> {aPackage.start_date} </span> {aPackage.end_date && ' a '}
                    <span> {aPackage.end_date}</span>.
                  </div>
                  <div className="event-package-brand">
                    <img src={SportivaLogo} alt="Logo Sportiva" />
                  </div>
                </div>
              </div>
              <div className="event-package-secondary-misc">
                <div className="event-package-details">
                  <h3 className="event-package-header">Incluye:</h3>
                  <ul className="event-package-list">
                    {aPackage.details.map((detail) => (
                      <li key={detail}>{detail}</li>
                    ))}
                  </ul>
                </div>
                <div className="event-package-add-ons">
                  <h3 className="event-package-header"> Opcional:</h3>

                  <ul className="event-package-list">
                    {aPackage.add_ons.map((add_on) => (
                      <li key={add_on.name}>
                        {add_on.name} (${add_on.price} USD)
                      </li>
                    ))}
                  </ul>
                </div>
              </div>

              {aPackage.room_configuration.length > 1 && (
                <div className="event-package-room-config">
                  <h3 className="event-package-header">
                    Configuración de Habitaciones
                  </h3>
                  <table className="event-package-room-config-table">
                    <tr>
                      <th></th>
                      {aPackage.room_configuration[0].rooms_available.map(
                        (room_type) => {
                          return <th>{room_type}</th>;
                        }
                      )}
                    </tr>
                    {aPackage.room_configuration.map((room) => {
                      const { type, simple, double, triple, quadruple } = room;
                      return (
                        <tr>
                          <td>{type ? type : "N/A"}</td>
                          {simple ? (
                            <td>{"$" + simple + " USD"}</td>
                          ) : (
                            <td></td>
                          )}
                          {double ? (
                            <td>{"$" + double + " USD"}</td>
                          ) : (
                            <td></td>
                          )}
                          {triple ? (
                            <td>{"$" + triple + " USD"}</td>
                          ) : (
                            <td></td>
                          )}
                          {quadruple ? (
                            <td>{"$" + quadruple + " USD"}</td>
                          ) : (
                            <td></td>
                          )}
                        </tr>
                      );
                    })}
                  </table>
                  <div className="event-package-room-config-table-caveat">
                    <em>
                      * Precio desglosado {reservation_breakdown}. Reserva con $
                      {reservation_fee} {reservation_fee_currency},{" "}
                      {refundable ? "Reembolsables" : "NO Reembolsables"}.{" "}
                      {reservation_extra_caveat ? reservation_extra_caveat : ""}
                    </em>
                    {
                      (reservation_fee_caveat || reservation_extra_caveat) &&
                      <>
                        <hr />
                        <em>
                          Información Adicional: {reservation_fee_caveat}. {reservation_extra_caveat}
                        </em>
                      </>
                    }
                  </div>
                </div>
              )}
              <img
                className="event-bottom-logo"
                src={SportivaLogo}
                alt="SportivaCR Logo."
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default EventDetails;
