const prod = {
    API_URL: "https://sportivacr/api/prod",
};

const beta = {
    API_URL: "https://sportivacr/api/beta",
}

export const assetsURL = "https://dycwvoobkzuea.cloudfront.net";
export const apiURL = "https://9wmkluzp2c.execute-api.us-west-2.amazonaws.com/prod";

export const config = process.env.NODE_ENV === "development" ? beta : prod;
