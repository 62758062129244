import React from "react";

import CardList from "../cardlist/cardlist";
import Jumbotron from "../misc/jumbotron/jumbotron";
import "./home.scss";

import { assetsURL } from "../../constants";

const splash = `${assetsURL}/img/banner-sportiva.jpg`;

const Home = () => {
  return (
    <div id="home">
      <Jumbotron splash={splash} />
      <h1 className="home-main-events-header">Eventos Principales</h1>
      <CardList />
    </div>
  );
};
export default Home;
