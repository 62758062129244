import React, { useRef, useState, useEffect } from "react";
import axios from "axios";
import AWS from "aws-sdk";
import Card from "./card/card";
import Modal from "../misc/modal/modal";
import "./cardlist.scss";
import { apiURL } from "../../constants";

const CardList = () => {
  const { unmarshall } = AWS.DynamoDB.Converter;

  const modalRef = useRef();

  const [modalContent, setModalContent] = useState();
  const [events, setEvents] = useState();

  const getEvents = async () => {
    let events = (await axios(apiURL + '/event')).data.Items
      .map(e => unmarshall(e))
      .filter(e => !e.hidden) // Hide inactive events
      .sort((a, b) => a.order - b.order);

    setEvents(events);
  };

  useEffect(() => {
    !events && getEvents();

  }, [setEvents, events]);

  const handleModalContentChange = content => {
    setModalContent(content);
    modalRef.current.open();
  };

  return events ? 
  
      <div className="cardlist-container">
        {events.map(event_details =>
          <Card
            key={`${event_details.event}-key`}
            event_details={event_details}
            handleModalContentChange={handleModalContentChange}
          />
        )}

        <Modal ref={modalRef}>{modalContent}</Modal>
      </div>

  : //else render loading spinner

    <div className="lds-container">
      <div className="lds-ripple">
        <div></div>
        <div></div>
      </div>
    </div>

};

export default CardList;
