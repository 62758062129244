import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from "./components/home/home";
import ContactUs from "./components/contact_us/contact_us"
import Nav from "./components/nav/nav";
import "./_globals.scss";

function App() {
  return (
    <Router>
      <Nav/>
      <Switch>
        <Route path="/contacto">
          <ContactUs/>
        </Route>
        {/* <Route path="/about">Hello about</Route> */}
        <Route path="/">
          <Home/>
        </Route>
        <Route path="/event-details">
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
