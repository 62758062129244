import React from "react";

import "./nav.scss";

import { assetsURL } from "../../constants";
const logo = `${assetsURL}/svg/logo-letras-negro.svg`;

const Nav = () => {
  return (
    <div className="nav">
      <input type="checkbox" id="nav-check" />
      <div className="nav-header">
        <a href="/">
          <img
            className="nav-header"
            src={logo}
            alt="Sportiva Logo"
            width="64px"
          />
        </a>
      </div>

      <div className="nav-btn">
        <label htmlFor="nav-check">
          <span></span>
          <span></span>
          <span></span>
        </label>
      </div>

      <div className="nav-links">
        <a href="//github.io/jo_geek" target="_blank" rel="noreferrer">
          Maratones
        </a>
        <a
          href="http://stackoverflow.com/users/4084003/"
          target="_blank"
          rel="noreferrer"
        >
          Medias Maratones
        </a>
        <a
          href="https://in.linkedin.com/in/jonesvinothjoseph"
          target="_blank"
          rel="noreferrer"
        >
          Todos los eventos
        </a>
        <a href="https://codepen.io/jo_Geek/" target="_blank" rel="noreferrer">
          Contacto
        </a>
        <a
          href="https://jsfiddle.net/user/jo_Geek/"
          target="_blank"
          rel="noreferrer"
        >
          Sobre Nosotros
        </a>
      </div>
    </div>
  );
};

export default Nav;
