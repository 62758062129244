import React from "react";

import WhatsappSVG from "../misc/icons/whatsapp-svg/whatsapp-svg";
import "./contact_us.scss";
import ContactForm from "../misc/contact-form/contact-form";
import { assetsURL } from "../../constants";

const SportivaLogo = `${assetsURL}/svg/logo-letras-negro.svg`;
const ContactUs = () => {
  return (
    <div className="contact-us-container">
      <img
        className="contact-us-top-logo"
        src={SportivaLogo}
        alt="SportivaCR Logo."
      />

      <ContactForm>
        <h4>
          Puede llamarnos al +506 8820-8284 o escribirnos por Whatsapp{" "}
          <WhatsappSVG />
        </h4>
      </ContactForm>
    </div>
  );
};

export default ContactUs;
