import React, { useState } from "react";
import "./card.scss";

import WhatsappSVG from "../../misc/icons/whatsapp-svg/whatsapp-svg";

import EmailSVG from "../../misc/icons/email-svg/email-svg";
import EventSVG from "../../misc/icons/event-svg/event-svg";
import { assetsURL } from "../../../constants";

const Card = ({ event_details, handleModalContentChange }) => {
  const {
    event,
    title,
    description,
    event_date,
    event_inscription_status,
    event_package_reservation_status,
    official_partner,
  } = event_details;

  const [picLoaded, setPicLoaded] = useState(false);
  return (
    <div className="card-container">
      <div className="card-header">
        {!picLoaded && (
          <div className="lds-container">
            <div className="lds-ripple">
              <div></div>
              <div></div>
            </div>
          </div>
        )}
        <img
          onLoad={() => {
            setPicLoaded(true);
          }}
          className="card-header-img"
          width="100%"
          src={
            assetsURL + "/img/events/" + event + "/" + event + "-400x200.jpg"
          }
          alt={`${event} marathon`}
        />
      </div>
      <div className="card-body">
        <div className="card-body-header">
          <h2 className="card-body-title">{title}</h2>
          {official_partner && (
            <div className="card-body-title-badge">Tour Operador Oficial</div>
          )}
        </div>
        <div className="card-body-description">{description}</div>

        <div className="card-body-grid">
          <div className="event-status-item">
            <h5>Reserva de Paquetes</h5>
            <div>{event_package_reservation_status}</div>
            <h5>Fecha del Evento</h5>
            <div>
              {Array.isArray(event_date) ? (
                <ul>
                  {event_date.map((date) => (
                    <li key={`${date}-key`}>{date}</li>
                  ))}
                </ul>
              ) : (
                event_date
              )}
            </div>
          </div>
          <div className="event-status-item">
            <h5>Inscripciones al Evento</h5>
            <div>{event_inscription_status}</div>
          </div>
        </div>
      </div>
      <div className="card-footer">
        <h4 className="card-footer-h4">Más Información</h4>
        <div className="btn-group">
          <div className="btn-element open-event-btn">
            <EventSVG
              modal_trigger={handleModalContentChange}
              event_details={event_details}
              className="event-btn-card"
            />
            <div className="btn-label">Ver Evento</div>
          </div>

          <div className="btn-element">
            <WhatsappSVG event_title={event} />
            <div className="btn-label">Whatsapp</div>
          </div>
          <div className="btn-element">
            <EmailSVG modal_trigger={handleModalContentChange} />
            <div className="btn-label">Correo</div>
          </div>
          {/* <div className="btn-element">
            <MessengerSVG />
            <div className="btn-label">Messenger</div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Card;
