import React, { useState, useEffect } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { assetsURL, apiURL } from "../../../constants";
import WhatsappSVG from "../icons/whatsapp-svg/whatsapp-svg";
import ProcessingSVG from "../icons/processing-svg/processing-svg";
import OkSVG from "../icons/ok-svg/ok-svg";
import "./contact-form.scss";

const SportivaLogo = `${assetsURL}/svg/logo-letras-negro.svg`;

const ContactForm = ({ modal_trigger, children }) => {
  const [submitResult, setSubmitResult] = useState();
  const [events, setEvents] = useState();
  const [processing, setProcessing] = useState(false);

  const getEvents = async () => {
    let data = (await axios.get(apiURL + "/event/titles")).data.Items;
    data = data.map((i) => i.title.S);
    setEvents(data);
  };

  useEffect(() => {
    if (!events) {
      getEvents();
    }
    if (submitResult) {
      if (
        submitResult.status === 200 &&
        submitResult.data.message === "Email sent successfully"
      ) {
        const messageBanner = (
          <div className="submit-result-banner">
            <h3>¡Su mensaje se ha enviado con éxito!</h3>
            <p>Lo estaremos contactando en breve.</p>
            <h5>
              Gracias, <br />
              Sportiva CR.
            </h5>
          </div>
        );
        modal_trigger(messageBanner);
      } else {
        console.log(submitResult);
        modal_trigger(
          <div className="submit-result-banner">
            <h3>
              Hmmm... parece que ocurrió un error cuando se intentó enviar su
              consulta.
            </h3>
            <p>Por favor intente de nuevo o bien, puede contactarnos:</p>
            <div className="submit-result-banner-contact-methods">
              <ul>
                <li>
                  Por correo, directamente a{" "}
                  <em className="submit-result-banner-email">
                    info@sportivacr.com
                  </em>
                </li>
                <li>
                  o por Whatsapp <WhatsappSVG />
                </li>
              </ul>
              <p>Atenderemos a su consulta en breve.</p>
              <h4>
                Disculpe el inconveniente,
                <br />
                Sportiva
              </h4>
              <hr></hr>
              Datos del error:
              <br />
              HTTP Code: {submitResult.status}
              <br />
              {submitResult.data.message &&
                `Response Message: ${submitResult.data.message}`}
            </div>
          </div>
        );
      }
    }
  }, [modal_trigger, submitResult, events]);

  const phoneRegExp =
    /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;

  if (events) {
    return (
      <div className="contact-form-container">
        <h1 className="contact-form-header">Contactar a Sportiva</h1>
        {children}
        <p className="contact-form-opening-message">
          Gracias por contactarnos. Por favor, ingrese la información que se le
          solicita. Nosotros le responderemos tan pronto como nos sea posible a
          la dirección de correo electrónico que usted especifique a
          continuación.
        </p>
        <Formik
          initialValues={{
            first_name: "",
            last_name: "",
            email: "",
            phone: "",
            preferred_contact_method: "Email",
            event_name: "N/A",
            inquiry_body: "",
          }}
          validationSchema={Yup.object({
            first_name: Yup.string()
              .max(15, "Este campo no puede ser mayor a 15 caractéres.")
              .required("Este valor es obligatorio."),
            last_name: Yup.string()
              .max(20, "Este campo no puede ser mayor a 20 caractéres.")
              .required("Este valor es obligatorio."),
            email: Yup.string()
              .email("El correo electrónico ingresado es inválido.")
              .required("Este valor es obligatorio."),
            inquiry_body: Yup.string().required("Este valor es obligatorio."),
            phone: Yup.string().matches(
              phoneRegExp,
              "Phone number is not valid"
            ),
          })}
          onSubmit={async (values, { setSubmitting, resetForm }) => {
            try {
              setProcessing(true);
              setSubmitResult(
                await axios.post(
                  apiURL +
                    `/web-inquiry?name=${values.first_name}&last_name=${
                      values.last_name
                    }&email=${values.email}&event_name=${
                      values.event_name
                    }&inquiry_body=${encodeURI(
                      values.inquiry_body
                    )}&preferred_contact_method=${
                      values.preferred_contact_method
                    }&phone=${values.phone}`
                )
              );
              setSubmitting(false);
              setProcessing(false);
              resetForm();
            } catch (error) {
              console.log(error);
              setSubmitResult(error.response);
              setSubmitResult(false);
              resetForm();
              setProcessing(false);
            }
          }}
        >
          <Form className="contact-form">
            <div className="contact-form-section">
              <h3 className="contact-form-item-header">Su Información</h3>
              <div className="contact-form-item">
                <label htmlFor="first_name">Nombre</label>
                <Field placeholder="requerido" name="first_name" type="text" />
                <div className="contact-form-error-message">
                  <ErrorMessage
                    className="contact-form-error"
                    name="first_name"
                  />
                </div>
              </div>

              <div className="contact-form-item">
                <label htmlFor="last_name">Apellido(s)</label>
                <Field placeholder="requerido" name="last_name" type="text" />
                <div className="contact-form-error-message">
                  <ErrorMessage
                    className="contact-form-error"
                    name="last_name"
                  />
                </div>
              </div>
              <div className="contact-form-item">
                <label htmlFor="email">Email</label>
                <Field placeholder="requerido" name="email" type="email" />
                <div className="contact-form-error-message">
                  <ErrorMessage className="contact-form-error" name="email" />
                </div>
              </div>
              <div className="contact-form-item">
                <label htmlFor="phone">Telefono</label>
                <Field name="phone" placeholder="opcional" type="phone" />
                <div className="contact-form-error-message">
                  <ErrorMessage className="contact-form-error" name="phone" />
                </div>
              </div>
            </div>

            <div className="contact-form-section">
              <label className="preferred_contact_method">
                Método de contact preferido
              </label>
            </div>
            <div className="contact-form-section">
              <label>
                <Field
                  type="radio"
                  checked
                  name="preferred_contact_method"
                  value="Email"
                />
                Email
              </label>
              <label>
                <Field
                  type="radio"
                  name="preferred_contact_method"
                  value="Teléfono"
                />
                Teléfono (Whatsapp, SMS, llamada)
              </label>

              <h3 className="contact-form-item-header">Su Consulta</h3>
              <div className="contact-form-item">
                <label htmlFor="event_name">Evento a consultar</label>
                <Field name="event_name" as="select" id="event_name">
                  <option value="N/A">N/A</option>
                  {events.map((event) => (
                    <option key={`${event}-key`} value={event}>
                      {event}
                    </option>
                  ))}
                  <option value="otro">Otro</option>
                </Field>
              </div>
            </div>
            <div className="contact-form-section contact-form-inquiry">
              <label htmlFor="inquiry_body">Asunto</label>
              <Field name="inquiry_body" rows="5" as="textarea" />
              <div className="contact-form-error-message">
                <ErrorMessage
                  className="contact-form-error"
                  name="inquiry_body"
                />
              </div>
            </div>
            {processing ? <ProcessingSVG>Procesando...</ProcessingSVG> : <OkSVG type="submit">Enviar</OkSVG> }
          </Form>
        </Formik>
        <img
          className="event-bottom-logo"
          src={SportivaLogo}
          alt="SportivaCR Logo."
        />
      </div>
    );
  }
  return <></>;
};

export default ContactForm;
